@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Roboto', system-ui, sans-serif;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
  }
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

@layer components {
  .btn {
    @apply px-6 py-2 rounded-lg transition-all duration-300 font-medium;
  }
  .btn-primary {
    @apply bg-accent text-white hover:bg-accent/90;
  }
  .btn-outline {
    @apply border-2 border-primary text-primary hover:bg-primary hover:text-white;
  }
  .container {
    @apply mx-auto px-4 sm:px-6 lg:px-8;
  }
  .section {
    @apply py-16 md:py-24;
  }
}
